// special page so that we can test the chat client

import React from "react";
import ChatClientTest from "../../components/ChatClient/ChatClientTest";

const AiInsightTest = () => {
  return (
    <>
      <ChatClientTest />
    </>
  );
};

export default AiInsightTest;