import "./App.css";

import React, { useContext, useCallback } from "react";
import { Authenticator } from "@aws-amplify/ui-react";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { QueryClient, QueryClientProvider } from "react-query";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Profile } from "./pages/profile/Profile";
import { RequireAuth } from "./RequireAuth";
import { Login } from "./components/Login";
import { EquityReport } from "./pages/pfc_reports/equity_report/EquityReport";
import { Dashboard } from "./pages/pfc_dashboard/Dashboard";
import { MGADashboard } from "./pages/mga_dashboard/MGADashboard";
import { AgentDashboard } from "./pages/pfc_dashboard/agentDashboard/AgentDashboard";
import { ConcentrationReport } from "./pages/pfc_reports/ConcentrationReport";
import { PlanListPage } from "./pages/PlanListPage";

import AdminUsersView from "./pages/user_view/AdminUsersView";
import CustomerView from "./pages/customer_view/CustomerView";
import BudgetPlanView from "./pages/budget_plans/BudgetPlanView";
import AgentMaps from "./components/AgentMaps/AgentMaps";
import AiInsight from "./pages/ai_insight/AiInsight";
import AiInsightTest from "./pages/ai_insight_test/AiInsightTest";
import AiInsightLogs from "./pages/ai_insight_logs/AiInsightLogs";

import Layout from "./components/Layout";
import { IncomeDashboard } from "./pages/pfc_dashboard/income_dashboard/IncomeDashboard";
import { RevenueDetails } from "./pages/pfc_reports/revenue_details/RevenueDetails";
import { PremiumDetails } from "./pages/pfc_reports/premium_details/PremiumDetails";
import { Writeoffs } from "./pages/pfc_reports/writeoffs/Writeoffs";
import { PaidFees } from "./pages/pfc_reports/paidfees/PaidFees";
import { Routes, Route } from "react-router-dom";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import { ActualVPlanSegment } from "./pages/pfc_dashboard/actual_v_plan_segment/ActualVPlanSegment";
import { StateContext } from "./StateContext";
import useGoogleTagPageView from './useGoogleTagPageView';

const queryClient = new QueryClient();


function MyRoutes() {
    const { clientType, setClientType } = useContext(StateContext);
    return (
      <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={
            <RequireAuth>
              {clientType === "mga" ? <MGADashboard /> : <Dashboard />}
            </RequireAuth>
          }
        />
          <Route
            path="/reports/actualvplan"
            element={
              <RequireAuth>
                <ComingSoon />
                {/* @@TODO: switch this back when the page is developed */}
                {/*<ActualvPlan />*/}
              </RequireAuth>
            }
          />
          <Route
            path="/reports/equity"
            element={
              <RequireAuth>
                <EquityReport />
              </RequireAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/incomedashboard"
            element={
              <RequireAuth>
                <IncomeDashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/segmentdashboard"
            element={
              <RequireAuth>
                <ActualVPlanSegment />
              </RequireAuth>
            }
          />
          <Route
            path="/agentdashboard"
            element={
              <RequireAuth>
                <AgentDashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/reports/concentration"
            element={
              <RequireAuth>
                <ConcentrationReport />
              </RequireAuth>
            }
          />
          <Route
            path="/reports/revenuedetails"
            element={
              <RequireAuth>
                <RevenueDetails />
              </RequireAuth>
            }
          />
          <Route
            path="/reports/premiumdetails"
            element={
              <RequireAuth>
                <PremiumDetails />
              </RequireAuth>
            }
          />
          <Route
            path="/reports/writeoffs"
            element={
              <RequireAuth>
                <Writeoffs />
              </RequireAuth>
            }
          />
          <Route
            path="/reports/paidfees"
            element={
              <RequireAuth>
                <PaidFees />
              </RequireAuth>
            }
          />
          <Route
            path="/plans"
            element={
              <RequireAuth>
                <PlanListPage />
              </RequireAuth>
            }
          />
          <Route
            path="/annualplan/:fiscalyear"
            element={<RequireAuth></RequireAuth>}
          />
          <Route
            path="/users"
            element={
              <RequireAuth>
                <AdminUsersView />
              </RequireAuth>
            }
          />
          <Route
            path="/customers"
            element={
              <RequireAuth>
                <CustomerView />
              </RequireAuth>
            }
          />
          <Route
            path="/budgets"
            element={
              <RequireAuth>
                <BudgetPlanView />
              </RequireAuth>
            }
          />
          <Route
            path="/aiinsight"
            element={
              <RequireAuth>
                <AiInsight />
              </RequireAuth>
            }
          />
          <Route
            path="/aiinsighttest"
            element={
              <RequireAuth>
                <AiInsightTest />
              </RequireAuth>
            }
          />
          <Route
            path="/aiinsightlogs"
            element={
              <RequireAuth>
                <AiInsightLogs />
              </RequireAuth>
            }
          />
          <Route
            path="/agentmaps"
            element={
              <RequireAuth>
                <AgentMaps />
              </RequireAuth>
            }
          />
          <Route
            path="/programdashboard"
            element={
              <RequireAuth>
                <AgentMaps />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    );
}

function App() {
  useGoogleTagPageView();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Authenticator.Provider>
        <QueryClientProvider client={queryClient}>
              <MyRoutes />
        </QueryClientProvider>
      </Authenticator.Provider>
    </LocalizationProvider>
  );
}

export default App;
