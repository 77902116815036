import React, { useState, useEffect, useRef } from "react";
import { ChatHistoryType, ChatHistoryMessageType, BackendChatHistoryMessageType } from "./types";
import { fetchGptChatResponse } from "../../api/utils/fetchGptChatResponse/fetchGptChatResponse";
import { fetchGptChatHistoryById } from "../../api/utils/fetchGptChatHistoryById/fetchGptChatHistoryById";
import { addChatMessage, removeLoadingChatMessage, isAIInsightContentType } from "./utils";
import ChatInput from "./ChatInput/ChatInput";
import { showChartPopup } from "../ChartPopup/ChartPopup";

import {
  ChatClientContainer,
  StyledTopBar,
  StyledHeading,
  ChatScrollContainer,
  StyledLinearProgress,
} from "./styles";
import { ChatMessage } from "./ChatMessage/ChatMessage";
import { WelcomeMessage } from "./WelcomeMessage/WelcomeMessage";

const ChatClientTest: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chatHistoryUIMessages, setChatHistoryUIMessages] = useState<ChatHistoryMessageType[]>([]);
  const [historyId, setHistoryId] = useState<string>('');
  const [chartConfig, setChartConfig] = useState<string>('');
  const [chartData, setChartData] = useState<string>('');
  
  const chatHistoryId = useRef<string>('');
  const conversationTitle = useRef<string>('Title');
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollDown = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const triggerChatError = (errorMessage?: string) => {
    addChatMessage(
      "assistant",
      errorMessage || "Error: please try again or edit your query",
      setChatHistoryUIMessages,
      undefined,
      undefined,
      "gptResponseError",
    );
  };

  const handleSubmit = async (userMessage: string) => {
    setIsLoading(true);

    addChatMessage(
      "user",
      userMessage,
      setChatHistoryUIMessages,
      undefined,
      undefined,
      "userMessage",
    );

    try {
      let requestIsRunning = true;
      let isConsecutiveRequest = false;
      let loopCounter = 0;
      while (requestIsRunning && loopCounter < 2) {
        const response = await fetchGptChatResponse({ userMessage, isConsecutiveRequest, logHistory: true, chatHistoryId: historyId});
        const { chatHistoryTitle, query, chatResponse, needConsecutiveRequest, hadFailure } = response;

        if ((chatResponse || isConsecutiveRequest)) {
          chatHistoryId.current = response.chatHistoryId;
          setHistoryId(response.chatHistoryId);
          conversationTitle.current = chatHistoryTitle;
          
          addChatMessage(
            "assistant",
            chatResponse,
            setChatHistoryUIMessages,
            query,
            response?.queryResults,
            "gptResponse",
          );

          if (response?.chart) {
            setChartConfig(JSON.stringify(response.chart, null, 2));
          }
          if (response?.chartData) {
            setChartData(JSON.stringify(response.chartData, null, 2));
          }
                    // now check to see if there's a chart in the response
          if (response?.chart && response?.chartData) {
            showChartPopup(response);
          }
        }

        if (needConsecutiveRequest) {
          let inBetweenResponse = hadFailure ? loopCounter > 1 ? "Let my try one last time..." : "Something went wrong! Let me try again" : "Let me fetch the Database Results...";
          addChatMessage(
            "assistant",
            inBetweenResponse,
            setChatHistoryUIMessages,
            "",
            undefined,
            "gptResponse",
          );
        } else {
          requestIsRunning = false;
        }

        isConsecutiveRequest = needConsecutiveRequest as boolean;
        loopCounter++;
      }
    } catch (error) {
      triggerChatError();
    } finally {
      setIsLoading(false);
    }
  };

  const handleChartConfigChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChartConfig(e.target.value);
  };

  const handleChartDataChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChartData(e.target.value);
  };

  const handleShowChart = () => {
    try {
      const parsedConfig = JSON.parse(chartConfig);
      const parsedData = JSON.parse(chartData);
      showChartPopup({ chart: parsedConfig, chartData: parsedData });
    } catch (error) {
      console.error('Invalid JSON:', error);
      alert('Invalid JSON format');
    }
  };

  useEffect(() => {
    const storedHistoryId = localStorage.getItem('chatHistoryId');
    if (storedHistoryId) {
      setHistoryId(storedHistoryId);
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      addChatMessage(
        "assistant",
        "loading",
        setChatHistoryUIMessages,
        "",
        undefined,
        "loading",
      );

    }
    if (!isLoading) {
      removeLoadingChatMessage(setChatHistoryUIMessages);
    }
    scrollDown();
  }, [isLoading]);

  return (
    <div>
      <StyledTopBar>
        <StyledHeading level={4} placeholder={null}>
        </StyledHeading>
      </StyledTopBar>

      <ChatClientContainer>
        <ChatScrollContainer placeholder={null} ref={scrollRef}>
          {chatHistoryUIMessages.length === 0 && <WelcomeMessage />}
          {chatHistoryUIMessages.map((chat, index) => (
            <ChatMessage
              key={index}
              conversationTitle={conversationTitle.current}
              chatItem={chat}
            />
          ))}
        </ChatScrollContainer>

        {isLoading && <StyledLinearProgress variant={"indeterminate"} />}
        <ChatInput
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />

        <div className="p-4 border-t border-gray-200">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Chart Configuration
            </label>
            <textarea
              value={chartConfig}
              onChange={handleChartConfigChange}
              className="w-full p-2 border rounded-md h-48 font-mono"
              placeholder="Enter chart configuration JSON"
            />
          </div>
          
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Chart Data
            </label>
            <textarea
              value={chartData}
              onChange={handleChartDataChange}
              className="w-full p-2 border rounded-md h-48 font-mono"
              placeholder="Enter chart data JSON"
            />
          </div>
          
          <button
            onClick={handleShowChart}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Show Chart
          </button>
        </div>
      </ChatClientContainer>
    </div>
  );
};

export default ChatClientTest;