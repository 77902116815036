import React from "react";
import { createRoot } from "react-dom/client";
import { createPortal } from "react-dom";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface ChartPopupProps {
  json: any;
  onClose: () => void;
}

type ChartType = "line" | "area" | "bar" | "pie" | "donut" | "radialBar" |
  "scatter" | "bubble" | "heatmap" | "candlestick" | "boxPlot" | "radar" |
  "polarArea" | "rangeBar" | "treemap" | "rangeArea";

interface ChartSeries {
  name: string;
  type?: string;
  data: number[];
}

const ChartPopup: React.FC<ChartPopupProps> = ({ json, onClose }) => {
  const processChartConfiguration = () => {
    try {
      if (!json.chartData || !json.chartOptions) {
        throw new Error("Invalid chart configuration");
      }

      let baseChartType: ChartType = (json.chartType === "combo" ? "line" : json.chartType as ChartType) || "line";
      
      // Process the series data
      const chartSeries = json.chartData.map((series: ChartSeries) => ({
        name: series.name,
        type: series.type || baseChartType,
        data: series.data
      }));

      // Base chart options
      const chartOptions: ApexOptions = {
        chart: {
          type: baseChartType,
          stacked: false,
          height: 350,
          zoom: {
            enabled: true
          }
        },
        xaxis: {
          categories: json.chartOptions.xaxis?.categories || [],
          title: {
            text: json.chartXAxis || ''
          }
        },
        yaxis: {
          title: {
            text: json.chartYAxis || ''
          },
          labels: {
            formatter: function(value) {
              if (json.chartYAxis?.includes('$')) {
                return `$${value.toLocaleString('en-US', { 
                  minimumFractionDigits: 2, 
                  maximumFractionDigits: 2 
                })}`;
              }
              return value.toLocaleString('en-US');
            }
          }
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function(value) {
              if (json.chartYAxis?.includes('$')) {
                return `$${value.toLocaleString('en-US', { 
                  minimumFractionDigits: 2, 
                  maximumFractionDigits: 2 
                })}`;
              }
              return value.toLocaleString('en-US');
            }
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center'
        },
        stroke: {
          width: chartSeries.map((series: { type?: string }) => 
            series.type === 'bar' ? 0 : 2
          ),
          curve: 'smooth'
        }
      };

      return {
        chartSeries,
        chartOptions
      };
    } catch (error) {
      console.error("Error processing chart configuration:", error);
      return {
        chartSeries: [],
        chartOptions: {}
      };
    }
  };

  const { chartSeries, chartOptions } = processChartConfiguration();

  const overlayStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999
  };

  const modalStyle: React.CSSProperties = {
    backgroundColor: 'white',
    padding: '24px',
    borderRadius: '8px',
    width: '90%',
    maxWidth: '1024px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
  };

  const headerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px'
  };

  const titleStyle: React.CSSProperties = {
    fontSize: '20px',
    fontWeight: 600,
    margin: 0
  };

  const closeButtonStyle: React.CSSProperties = {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    fontSize: '24px',
    color: '#666',
    padding: '4px'
  };

  const chartContainerStyle: React.CSSProperties = {
    height: '400px'
  };

  const errorStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#ff0000',
    padding: '20px'
  };

  return createPortal(
    <div style={overlayStyle}>
      <div style={modalStyle}>
        <div style={headerStyle}>
          <h2 style={titleStyle}>
            {json.chatHistoryTitle || "Chart"}
          </h2>
          <button
            onClick={onClose}
            style={closeButtonStyle}
          >
            ✖
          </button>
        </div>
        
        {chartSeries.length > 0 ? (
          <div style={chartContainerStyle}>
            <Chart
              options={chartOptions}
              series={chartSeries}
              height="100%"
              width="100%"
            />
          </div>
        ) : (
          <p style={errorStyle}>
            Invalid chart configuration
          </p>
        )}
      </div>
    </div>,
    document.body
  );
};

export const showChartPopup = (json: any) => {
  const container = document.createElement("div");
  document.body.appendChild(container);

  const root = createRoot(container);

  const closePopup = () => {
    root.unmount();
    if (document.body.contains(container)) {
      document.body.removeChild(container);
    }
  };

  root.render(<ChartPopup json={json} onClose={closePopup} />);
};